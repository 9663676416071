import React from "react";
import Slider from "react-slick";
import { GatsbyImage } from "gatsby-plugin-image";
import Layout from "../components/layout";
import { graphql, Link } from "gatsby";
import Seo from "../components/seo";

export default function Home({data}) {

  const d = data.allWpPortfolioCategory.nodes;
  const h = data.allWpHero.nodes[0].heroCF.herogallery;
  const s = d.filter( x => x.wpParent === null);
  var speed = data.allWpHero.nodes[0].heroCF.speed;

  const settings = {
    infinite: true,
    autoplaySpeed: speed,
    adaptiveHeight: true,
    speed: 2618,
    autoplay: true,
    pauseOnHover: false,
    slidesToShow: 1,
    slidesToScroll: 1,
    dots: false,
    fade: true,
  };

  return (
    <Layout>
      <Seo
          title="Matt Parsons - Art and Design." isHome="true"
          description="My portfolio website showcasing a diverse range of commercial and non-commercial artwork from the last 30 years."
          image="https://mattparsons.eu/icons/icon-256x256.png"
      />
        <Slider {...settings}>
        {h.map((node, i) => (
          <img 
            key={i}
            style={{objectFit:'cover'}}
            src={node.mediaItemUrl}
            alt={node.localFile.name}
          />
        ))}
        </Slider>
      <div id="portfolio" className="row">
      {s.map((node, i) => (
          <div key={i} className="folio col-lg-3 col-md-6 col-sm-6 col-12">
            <Link to={`portfolio/${node.slug}`}>
              <GatsbyImage 
                style={{objectFit:'cover',height:'200px'}}
                image={node.extra.thumbnail.localFile.childImageSharp.gatsbyImageData}
                alt={node.name}
              />
              <h3 className="ptb--10" style={{textAlign:'center',fontFamily:'Bebas Neue',fontSize:'20px'}}>{node.name}</h3>
            </Link>
          </div>
      ))}
      </div>
    </Layout>
  )
}

export const pageQuery = graphql`
query {
  allWpHero {
    nodes {
      heroCF {
        speed
        herogallery {
          mediaItemUrl
          localFile {
            name
          }
        }
      }
    }
  }
  allWpPortfolioCategory(sort: {fields: extra___order}){
    nodes{
      extra {
        order
      }
      wpParent {
        node {
          slug
        }
      }
      wpChildren {
        nodes {
          slug
        }
      }
      name
      slug
      extra {
        thumbnail {
          mediaItemUrl
          localFile {
            childImageSharp {
              gatsbyImageData(
                height: 500,
                placeholder: DOMINANT_COLOR,
                formats: AUTO
              )
            }
          }
        }
      }
    }
  }
}
`
